import * as React from "react";
import { graphql } from "gatsby";
import How from "../modules/How";

const howIWorksPage = () => <How />;

export default howIWorksPage;

// export const Head = () => <title>How it works</title>;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      nodes {
        data
        ns
        language
      }
    }
  }
`;
